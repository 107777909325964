class __fs_popup{

    selector;

    constructor(selector) {
        this.selector = FS(selector);
    }

    show(){
        this.hideAll();
        this.selector.addUniqueClass('popup--active');
        FS('body').addClass('popup-showing');
    }

    hideAll(){
        FS('.popup--active', 'all').removeClass('popup--active');
        FS('body').removeClass('popup-showing');
    }


}


function popup(selector = null){
    return new __fs_popup(selector);
}
document.addEventListener('DOMContentLoaded', function() {
    FS('.popup-close-btn', 'all').onClick(function (){
       popup().hideAll();
    });
});

document.addEventListener('DOMContentLoaded', function() {


    if (document.getElementById('pdd-test-button-desc')) {
        descVisor();
    }

    if (document.getElementById('popup-pdd-test__buttons-next')) {
        document.getElementById('popup-pdd-test__buttons-next').addEventListener('click', function () {
            location.reload();
        });
    }


    if (document.getElementsByClassName('pdd-test-content__nav-item').length > 0) {
        let pdd_nav = document.querySelectorAll('.pdd-test-content__nav-item');
        pdd_nav.forEach(function (item) {
            item.addEventListener('click', function () {
                document.querySelectorAll('.pdd-test-content__nav .pdd-test-content__nav-active').forEach(n => n.classList.remove('pdd-test-content__nav-active'));
                document.querySelectorAll('.pdd-test-content__wrap .pdd-test-content__quest-active').forEach(n => n.classList.remove('pdd-test-content__quest-active'));
                let target = this.dataset.target;
                item.classList.add("pdd-test-content__nav-active");
                document.getElementById(target).classList.add("pdd-test-content__quest-active");
                FS('.pdd-test-content__variants-desc-show', 'all').removeClass('pdd-test-content__variants-desc-show');
            });
        });
    }


    if (document.getElementById('pdd-test-button-next')) {
        nextVisor();
    }

    function descVisor() {
        document.getElementById('pdd-test-button-desc').addEventListener('click', function () {
            let activeNavItem = document.getElementsByClassName('pdd-test-content__nav-active')[0];
            let target = activeNavItem.dataset.target;
            document.getElementById(target + '-desc').classList.toggle("pdd-test-content__variants-desc-show");
        });
    }

    function nextVisor() {
        document.getElementById('pdd-test-button-next').addEventListener('click', function () {

            let activeNavItem = document.getElementsByClassName('pdd-test-content__nav-active')[0];
            let target = activeNavItem.parentNode;
            target.nextElementSibling.getElementsByClassName('pdd-test-content__nav-item')[0].click();


            FS('.pdd-test-content__variants-desc-show', 'all').removeClass('pdd-test-content__variants-desc-show');

        });
    }

    if (document.getElementsByClassName('pdd-test-content__variant').length > 0) {

        let pdd_test_btn = document.querySelectorAll('.pdd-test-content__variant');
        pdd_test_btn.forEach(function (item) {
            item.addEventListener('click', function () {
                let correctInput = document.getElementById('correct-input');
                let failInput = document.getElementById('fail-input');
                let completedInput = document.getElementById('completed-input');
                let quest = this.dataset.quest;
                let correct = this.dataset.correct;
                document.querySelectorAll('.' + quest).forEach(n => n.disabled = true);
                document.querySelectorAll('.' + quest).forEach(n => n.classList.add('pdd-test-content__variant-disabled'));
                console.log(correct)
                if (correct == 1) {
                    item.classList.add("pdd-test-content__variant-correct");
                    correctInput.value = parseInt(correctInput.value) + 1;
                    document.getElementById(quest + '-nav').classList.add('pdd-test-content__nav-item-correct');
                } else {
                    item.classList.add("pdd-test-content__variant-fail");
                    failInput.value = parseInt(failInput.value) + 1;
                    document.getElementById(quest + '-nav').classList.add('pdd-test-content__nav-item-fail');


                }
                completedInput.value = parseInt(completedInput.value) + 1;
                if (parseInt(completedInput.value) >= 20) testDone();
            });
        });

    }

    function testDone() {
        let correct = document.getElementById('correct-input').value;
        let fail = document.getElementById('fail-input').value;
        let total = document.getElementById('completed-input').value;
        let result = 5 * correct; //вычисление процентов

        document.getElementById('pdd-result-correct').innerHTML = correct;
        document.getElementById('pdd-result-fail').innerHTML = fail;
        document.getElementById('pdd-result-total').innerHTML = total;
        document.getElementById('popup-pdd-test__result').innerHTML = result + '%';

        //document.getElementById('test-completed-popup-bg').classList.add('popup-active');


        console.log(111)
        popup('#test-popup').show();

        //document.getElementById('test-completed-popup-elem').classList.add('active');
    }

    if (document.getElementById('test-completed-popup-close')) {
        document.getElementById('test-completed-popup-close').addEventListener('click', function () {
            document.getElementById('test-completed-popup-bg').classList.remove('active');
            document.getElementById('test-completed-popup-elem').classList.remove('active');
        });
    }
});